@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";


@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;}
}

body {
  font-family: "Kreon";
}

body, .navbar, .gallery-navigation {
  background: url("texture_lilian_background.png");
  background-repeat: repeat;
}


.row, .workImage, .secondary-preview {
  animation: fadeInOpacity;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}

.workImage {
  opacity: 0;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}

.cv-buttons .btn {
  display: block;
  width: 50%;
  margin-left: 25%;
  margin-bottom: 10px;
}

.navbar {
  background-color: rgba(var(--bs-light), 0.97);
}


.gallery {
  margin: -10px;

  .gallery-content {
    margin-top: 2rem;
  }

  .gallery-description {
    text-align: center;
  }

  .gallery-item {
    height: 30vw;
    max-height: 470px;
    min-height: 360px;
  }


  .pb-5 {
    padding-bottom: 0rem !important;
  }

  .gallery-image {
    background-size: cover;
    background-position: center top;
  }

  .gallery-image-container {
    cursor: pointer;
  }

  .collection-preview {
    cursor: pointer;

    .secondary-preview {
      border-top: 3px solid var(--bs-light);
    }

    .secondary-preview:first-child {
      border-right: 3px solid var(--bs-light);
    }

    .secondary-preview:nth-child(3) {
      border-left: 3px solid var(--bs-light);
    }

    .gallery-image-container {
      box-shadow: none;
    }
  }

  .gallery-image-container {
    & > div:first-child {
      padding: 0 calc(var(--bs-gutter-x) * .5);
    }
  }
}

.contactRow{
  margin-top: 5px;
}

.h-80 {
  height: 80%;
}

.h-70 {
  height: 70%;
}

.h-60 {
  height: 60%;
}

.h-40 {
  height: 40%;
}

.h-30 {
  height: 30%;
}

.h-20 {
  height: 20%;
}

.max-vh-70 {
  max-height: 70vh;
}

.wDescription {
  font-family: Lora;
  font-size: 1rem;
  text-align: left;
}
.intro-content {
font-family: Lora;

}

.btn:focus {
  outline: none;
  box-shadow: none !important;
}

.gallery-navigation {

  padding-bottom: 5px;

  .m-2 {
    margin: 0.7rem !important;
  }

  .btn {
    border-width: 2px !important;
    font-weight: bold !important;
    font-family: Lora;
    font-size: 1rem;
    line-height: 1.8;
  }

  .btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 1rem;
  }

  .btn:not(.btn-dark) {
    background: none;
    border-color: var(--bs-dark);
  }

  .btn:active {
    background: var(--bs-secondary);
  }

  .btn-dark {
    border-color: var(--bs-dark);

    .close-icon {
      display: none;
      position: absolute;
      margin: -11px 0 0 0px;
    }

    &:hover, &:active {
      .close-icon {
        display: inline;
      }
    }
  }
}

textarea {
  border: var(--bs-secondary) 1px dashed;
}

textarea:focus {
  border-style: solid;
}

input {
  border: none;
  border-bottom: var(--bs-dark) 2px solid;
  width: 200px;
}

.gallery-navigation .btn {
  border-width: 2px !important;
  font-size: 15px;}

input:focus {
  border-bottom: var(--bs-secondary) 2px solid;
}

input, textarea {
  font-size: 13px;
  font-weight: bold;
  transition: border 0.6s;
  outline: none;
  background: none;
}


@import "~bootstrap/scss/bootstrap";

@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/utilities/api";

@import "theme.scss";
